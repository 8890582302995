@import "../../static/scss/variables.scss";
// .react-select__value-container {
//   border: 10px solid red !important;
// }
.select__single-value {
  font-weight: 500;
  font-size: 0.8rem;
  color: $grey !important;
}

.select__control--menu-is-open {
  border: 0px solid #deebff !important;
}

.select__control {
  width: 100%;
  &:hover {
    color: black !important;
    border: 1px solid #deebff !important;
  }
}

// .select__menu>div>div {
//     &:hover {
//         background-color: $primary-vlight;
//     }
// }
.select__option--is-selected {
  color: black !important;
  background-color: #deebff !important;
}

.cursor-pointer {
  cursor: pointer;
}

.no-filter-text {
  font-size: 14px;
}

.customeSelect > div:first-child > div:first-child {
  // height: 30px !important;
  overflow: auto !important;
}

.select__clear-indicator {
  display: none !important;
}
// .customeSelect:first-child > div  {
//     height: 50px!important;
//     overflow: auto!important;
//    }

.basic-multi-select {
  z-index: 99;
}
