.form-control:focus {
  color: none;
  border-color: wheat;
  background-color: none;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.table {
  width: 100%;
  margin-bottom: 0.5rem;
  color: #212529;

}

.table_title {
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 26%);
  background: #f1f1f1 !important;
  padding: 15px;
  margin-bottom: 15px !important;
  border-radius: 4px;
  border: 1px solid #eeeeee;
}

.table_title div h3 {
  background-color: white;
  padding-right: 10px;
}

.contents {
  width: 100%;
  // height: 80vh;
  // overflow: auto;
}

table {
  position: relative;
  font-size: 12px;
}

th {
  position: sticky;
  top: 0;
  z-index: 9;
}

.textSize {
  font-size: 12px;
}

.dataDisplay {
  .arrows {
    padding: 5px;
  }
  table {
    border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th:last-child {
    }
    th:not(:last-child) {
      //    width: 200px;
      //    text-align: center !important;
    }
    th {
      vertical-align: middle;
      background-color: #f1f1f1;
      border-top: 0px;
      select {
        border: 1px solid #818181 !important;
        border-radius: 3px;
      }
    }
    td:first-child {
      //   text-align: left!important;
    }
    th:first-child {
      // text-align: left!important;
    }
    th,
    td {
      vertical-align: middle;
      text-align: center;
      margin: 0;
      :last-child {
        border-right: 1;
      }
    }
  }
}

.paginationWrapper {
  display: flex;
  bottom: 15px;
  right: 15px;
}

.ant-picker-range {
  border-radius: 4px !important;
}

.table_custom td,
.table_custom th {
  border-top: none;
}

.table_custom th {
  background-color: #ffffff !important;
  // border-bottom: none !important;
  font-size: 14px !important;
}

.table_custom td {
  // font-size: 14px !important;
  padding: 8px 0px;
}

.btn-outline-dark {
  // color: #474747 !important;
  border-color: #aaaaaa !important;
}

.specific_table_custom th:last-child {
  // width: 400px !important;
  // background: #f1f1f1 !important;
  border-radius: 4px;
  text-align: center !important;
  padding-bottom: 10px !important;
  // border-left: 10px solid #ffffff;
}

.specific_table_custom td:last-child {
  // width: 400px !important;
  // background: #f1f1f1 !important;
  // border-radius: 4px;
  // text-align: center !important;
  // border-left: 10px solid #ffffff;
}

.table_custom th {
  background: #f1f1f1 !important;
}

.paginationWrapper {
  background: #f1f1f1;
  padding: 5px 25px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 26%);
  border: 1px solid #eeeeee;
}

// .main {
//   margin-left: 195px !important;
// }

.button_More:active {
  outline: none;
  box-shadow: none !important;
}
.button_More:focus {
  outline: none;
  box-shadow: none;
}
.button_More {
  padding: 5px 10px;
  outline: none;
  font-size: 11px;
  margin-left: 20px;
}

.link_button:active {
  outline: none;
  box-shadow: none !important;
}
.link_button:focus {
  outline: none;
  box-shadow: none;
}
.link_button {
  padding: 8px 15px;
  outline: none;
  font-size: 11px;
}
.file_exports {
  cursor: pointer;
  color: #555555 !important;
}



.noDataDiv{
  // height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;
  border-radius: 4px;
  font-weight: 500;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}


.noDataDiv>p{
  margin: 0 !important;
}

.box-shadow{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}


.ReportTableHead{
  background: white;
  border-radius: 0.3rem;
  box-shadow: 0px 0px 2px 1px rgba(145, 145, 145, 0.5);
}

.tableHeaderContent {
  margin-top: 27px;
}

.tableIcon {
  background: #3a3abf !important;
  color: white;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px #8181ec;
  padding: 1.2rem 1.15rem;
  margin-top: -77px;
  border-radius: 0.3rem;
  position: absolute;
  top: 14rem !important;
  left: 58px;
  z-index: 10;
}


.backColor{
  padding: 20px;
    background: #f7f7f7;
    border-radius: 5px;
    margin-top: 35px;
}
